import React from 'react'
import "./addashboard.scss"
import { ethers } from "ethers";
import { Button } from 'primereact/button'
export const AdDashBoard = () => {
  const fetchTran = async ()=>{
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org:443/')
    console.log(provider)
    const x = await provider.getTransaction("0x312f97a744694ad94b95da906342e9e5a61a07ad7773f93d9d5d3b21eaf556f9");
    console.log(x)
    console.log(x.from, x.to, (x.value*1)/10e17)
    

  }
  return (
    <div className='addashboard'>
      {/* <Button label='TRAN HASH' onClick={()=>fetchTran()} /> */}
    </div>
  )
}
