import React, { useEffect, useState, useRef } from "react";
import "./leveldetails.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";
import { Card } from "primereact/card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
export const LevelDetails = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [level, setLevel] = useState(1);
  const [nextFid, setNextFid] = useState();
  const [data, setData] = useState([]);
  const [hitUser, setHitUser] = useState({})
  const fetchData = async () => {
    const resp = await axios.post(
      url + "/admin/fetchleveldata",
      { level },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setData(resp.data.userlevel);
      setHitUser(resp.data.hitUser)
      setNextFid(resp.data.newId)
    } else {
      alert("Sorry");
    }
  };
  return (
    <div className="leveldetails">
      <div className="grid">
        <div className="col-12">
          <div className="levelpoint">
            <InputText
              placeholder="Provide 1 To 9"
              className="p-inputtext-sm mr-3"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            />
            <Button label="Fetch Data" onClick={() => fetchData()} />
            <Divider />
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <Card className="card">
            <div className="nextid">
              <div>
                <span>NEXT FID: </span>
                <span>{nextFid}</span>
              </div>
              <div>
                <span>HIT ID: </span>
                <span>{hitUser.fid}</span>
              </div>
              <div>
                <span>Email: </span>
                <span>{hitUser.email}</span>
              </div>
              <div>
              <div>
                <span>WhatsApp: </span>
                <span>{hitUser.whatsapp}</span>
              </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Users">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Mem Id</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Non-Working-ID</TableCell>
                    <TableCell align="center">Whats App</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.fid}</TableCell>
                      <TableCell align="center">{row.whatsapp}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
