import React, { useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import axios from "axios";
import "./userdashboard.scss";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
export const UserDashboard = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(0);
  const [helpTaken, setHelpTaken] = useState(0);
  const [helpGigen, setHelpGiven] = useState(0);
  const actemail = localStorage.getItem("p2pemailxemail");
  const [visible, setVisible] = useState(true);
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdetails",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );

      if (resp.status === 200) {
        setData(resp.data.userdata);
        setPending(resp.data.p);
        setHelpTaken(resp.data.helpTaken);
        setHelpGiven(resp.data.helpGiven);
      }
    };
    fetchData();
  }, [actemail, apikey, url]);
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Ref Link copied.",
    });
  };
  return (
    <div className="userdashboard">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6 left">
            <div className="p-1 border-round-sm">
              <div className="title">WELCOME TO</div>
              <div className="logotext">P2P</div>
              <div className="logotext">HELPING HAND</div>
              <div className="msg">
                <Divider />
                Uniquely Designed Helping Platform.
                <Divider />
              </div>
              {data.mail_verified === false ? (
                <>
                  <Link to="/user/mailverify">
                    <Button severity="danger" label="Mail Verify" />
                  </Link>
                </>
              ) : (
                <>
                  {data.is_active === 0 ? (
                    <Link to="/user/activateself">
                      <Button severity="danger" label="Activate Membership" />
                    </Link>
                  ) : (
                    <>
                      {pending === 1 ? (
                        <div>
                          <Link to="#">
                            <Button label="You have one Pending Response." />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-6 right">
            <div className="grid">
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link>Help Taken</Link>
                  </div>
                  <div className="val">${helpTaken.toFixed(2)}</div>
                </div>
              </div>
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link>Help Render</Link>
                  </div>
                  <div className="val">${helpGigen.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-12">
                <div className="text-left bg-red-200 text-blue-150 p-3 rounded-bl-lg">
                  <span className="text-gray-700">Ref Link: </span>
                  {data.is_active === 1 ? (
                    <>
                      <span className="reflink text-gray-700">
                        https://p2phelpinghand.com/signup/{data.memberid}
                      </span>
                      <span
                        className="pi pi-copy ml-2 copy"
                        onClick={() =>
                          copyText(
                            `https://p2phelpinghand.com/signup/${data.memberid}`
                          )
                        }
                      />
                    </>
                  ) : (
                    <span className="text-gray-700">
                      {" "}
                      Sorry!!! You cannot reffer.{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link to={data.is_active === 0 ? "#" : "/user/mydownline"}>
                      TOTAL DIRECT
                    </Link>
                  </div>
                  <div className="val">{data.mydown}</div>
                </div>
              </div>
              <div className="col-6 md:col-6 lg:col-6">
                <div className="text-center cust-bg">
                  <img src="/icons/invest.webp" width={40} alt="" />
                  <div>
                    <Link to={data.is_active === 0 ? "#" : "#"}>
                      TOTAL ACTIVE
                    </Link>
                  </div>
                  <div className="val">{data.actdown}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="Festival Offer"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <Alert severity="success" sx={{marginBottom:1}}>
          <AlertTitle>SMART WATCH</AlertTitle>3 direct active & 5 level upgrade.
        </Alert>
        <Alert severity="success" sx={{marginBottom:1}}>
          <AlertTitle>Lonavala Resort 3 Days 2 Night</AlertTitle>5 direct active & 5 level upgrade / 1 id active & 6 level upgrade.
        </Alert>
        <Alert severity="success" sx={{marginBottom:1}}>
          <AlertTitle>Goa Tour 3 days 2 night</AlertTitle>10 direct active & 5 level upgrade / 3 direct active & 6 level upgrade.
        </Alert>
        <Alert severity="success" sx={{marginBottom:1}}>
          <AlertTitle>Mumbai to Goa Cruise (Round trip)</AlertTitle>25 direct active & 5 level upgrade / 7 direct active & 6 level upgrade.
        </Alert>
        <Alert severity="success" sx={{marginBottom:1}}>
          <AlertTitle>Mumbai to Lakshadweep (round trip) Cruise 4 Night/5 days</AlertTitle>50 direct active & 5 level upgrade / 14 direct active & 6 level upgrade.
        </Alert>
        <Alert variant="filled" severity="warning">
        Offer Validity: Date 1st oct to 31st oct.
</Alert>
      </Dialog>
    </div>
  );
};
